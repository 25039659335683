import React, { useState, useEffect } from "react";
import { Container, Col, Row, Image } from "react-bootstrap";
import SEO from "../../components/shared/seo";
import { FadeUpBox, FadeIn } from "../../components/shared/transitions";
import { useStaticQuery, graphql, Link } from "gatsby";
import { CaseStudyNav } from "../../components/shared/case-study-nav";
import { Controller, Scene } from "react-scrollmagic";
import LayoutCaseStudy from "../../components/layouts/layout-case-study";
import { Helmet } from "react-helmet";
import Swiper from "react-id-swiper";
import technicalIcon from "../../images/technical-icon.svg";
import wesfarmersVideo from "../../video/Wesfarmers-case-study-video-light.mp4";
import videoPosterWesfarmers from "../../images/wesfarmers-video-poster.png";
import sitefinityLogo from "../../images/sitefinity_logo.png";
import timeIcon from "../../images/time-on-task-icon.svg";
import trainingIcon from "../../images/training.svg";
import paintbrushIcon from "../../images/paintbrush.svg";
import agileIcon from "../../images/agile.svg";

const swiperParams = {
  slidesPerView: "auto",
  spaceBetween: 15,
  freeMode: true,
  freeModeMomentumBounce: false,
  freeModeMomentum: false,
  freeModeMinimumVelocity: 0.2,
  longSwipesRatio: 0.8,
  loop: true,
  speed: 500,
  slidesOffsetAfter: 525,
  freeModeSticky: true,
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },
};

function WesfarmersSustainabilityPage({ location }) {
  // const { state = {} } = location;
  const [animate, setAnimate] = useState(false);
  const wesImages = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "wesfarmers-logo-sm.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      heroImage: file(
        relativePath: { eq: "hero-images-wesfarmers.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      eaCaseStudy: file(relativePath: { eq: "ea-electricity.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      sitefinityLogo: file(relativePath: { eq: "sitefinity_logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `);

  useEffect(() => {
    const timer = setTimeout(() => {
      setAnimate(true);
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  });

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {`
          {
            "@context": "http://schema.org",
            "@type": "Organization",
            "name": "Diviv Group",
            "description": ".NET applications, Progress Sitefinity CMS, Drupal, GovCMS, Sitecore Experience Platform, Microsoft Sharepoint, Dynamics CRM and Azure Hosting support in Perth Australia. Coupled with our creative and user experience team we are able to provide expert consultation and resources on a wide range of digital development projects across many industries.",
            "url": "https://www.diviv.com.au",
            "image": "https://www.diviv.com.au/icons/icon-96x96.png",
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "165 / 15 Aberdeen St",
              "addressLocality": "Perth",
              "addressRegion": "WA",
              "postalCode": "6000",
              "addressCountry": "AU"
            },
            "aggregateRating": {
              "@type": "AggregateRating",
              "ratingValue": "5",
              "ratingCount": "1"
            },
            "review": {
              "@type": "Review",
              "author": "Michael Johnson",
              "datePublished": "2020-03-09",
              "reviewBody": "Diviv is a solution based partner that delivers front and back end website solutions with innovation, professionalism and integrity. They are a great team and a pleasure to work with.",
              "reviewRating": {
                "@type": "Rating",
                "bestRating": "5",
                "ratingValue": "5",
                "worstRating": "1"
              }
            }
          }
          `}
        </script>
      </Helmet>
      <SEO
        title="Wesfarmers Sustainability"
        keywords="Diviv our work case studies"
        description="Diviv has extensive experience resolving complex digital problems for businesses in a wide variety of industries. Refer to our case studies to understand how we have delivered successful business outcomes for businesses like yours."
      />
      <LayoutCaseStudy>
        <div
          className="page-layout pb-0"
          data-menu-className="inverted"
          style={{
            backgroundImage: `url('${wesImages.heroImage.childImageSharp.fluid.src}')`,
          }}
        >
          <Container>
            <Row>
              <Col sm={12} md={12} lg={8}>
                <div className="client-logo--lg mb-4">
                  <Image src={wesImages.logo.childImageSharp.fluid.src} />
                </div>
                <FadeUpBox pose={animate ? "enter" : "exit"}>
                  <FadeIn>
                    <h6>Sitefinity Custom Development</h6>
                  </FadeIn>
                  <FadeIn duration={200}>
                    <h2>Wesfarmers Sustainability</h2>
                  </FadeIn>
                  <FadeIn duration={200}>
                    <p>
                      As part of a consolidation of Wesfarmers web portfolio, the previously separate Sustainability
                      sustainability.wesfarmers.com.au 300+ page site was to be migrated into the corporate domain
                      under a subfolder configuration.
                    </p>
                    <p>
                      Migration of this content meant that much of the previous styling would need to conform to the
                      corporate branding using existing elements and templates that were already developed and use
                      site wide for Wesfarmers.com.au.
                    </p>
                    <p>
                      Working with the Wesfarmers Sustainability team, Diviv looked at existing Wesfarmers page
                      templates and widgets to try and find the best fit for the content being moved over without
                      developing new templates.
                    </p>
                  </FadeIn>
                </FadeUpBox>
              </Col>

              <Col sm={12} md={12} lg={4}>
                <FadeUpBox pose={animate ? "enter" : "exit"}>
                  <FadeIn>
                    <div className="client-meta">
                      Discover what else Wesfarmers Sustainability is up to by visiting their
                      website:
                      <div className="client-meta__link">
                        <a href="https://wesfarmers.com.au/sustainability/" target="_blank">
                          Wesfarmers Sustainability
                        </a>
                      </div>
                    </div>
                  </FadeIn>
                </FadeUpBox>
              </Col>
            </Row>
          </Container>

          <Controller>
            <Scene
              triggerElement="carousel01"
              reverse={false}
              duration={2000}
              offset={200}
            >
              {(progress, event) => (
                <section id="carousel01">
                  <Container>
                    <Row
                      id="challenge"
                      className="carousel-section technical-challenge-slider-active"
                    >
                      <Col lg={6} sm={12} className="col-left">
                        <FadeUpBox pose={progress > 0 ? "enter" : "exit"}>
                          <FadeIn>
                            <h2>DEFINE EACH CHALLENGE</h2>
                            <p>
                              The biggest challenge with the project was retrofitting the Sustainability content
                              into the existing corporate website with its existing styles, widgets and templates.
                              This proved a difficult task for the combined Diviv / Sustainability team, and more
                              changes customisation of existing frameworks were required than originally planned.
                            </p>
                          </FadeIn>
                        </FadeUpBox>
                      </Col>

                      <Col lg={6} sm={12} className="col-right">
                        <FadeUpBox pose={progress > 0 ? "enter" : "exit"}>
                          <FadeIn>
                            <div className="carousel-slider-container">
                              <div className="carousel__outer swiper1">
                                <Swiper {...swiperParams}>
                                  <div className="swiper-slide">
                                    <div className="slide__icon">
                                      <img
                                        src={technicalIcon}
                                        alt="Technical icon"
                                      />
                                    </div>
                                    <div className="slide__number">01</div>
                                    <div className="slide__title">
                                      Challenge
                                    </div>
                                    <div className="slide__sub-title">
                                      Content Working with Corp Styles
                                    </div>
                                    <p>
                                      With a significant amount of content trying to find the best existing
                                      page layouts and widgets to best match the Sustainability proved a
                                      significant challenge. This was overcome by making sensible changes
                                      and customisation to existing Wesfarmers corporate components.
                                    </p>
                                  </div>
                                  <div className="swiper-slide">
                                    <div className="slide__icon">
                                      <img
                                        src={technicalIcon}
                                        alt="Technical icon"
                                      />
                                    </div>
                                    <div className="slide__number">02</div>
                                    <div className="slide__title">
                                      Challenge
                                    </div>

                                    <div className="slide__sub-title">
                                      User Friendly tabulated data
                                    </div>
                                    <p>
                                      The Sustainability team needed a user friendly approach to work with there data,
                                      improving upon the old Sustainability site and the ability to work with complex
                                      tabulated data Diviv build a Sitefinity Widget to allow the importing from an Excel
                                      export. This removed the need for content editors to have to make heavy edits and
                                      with just a few clicks a table could be exported and published.
                                    </p>
                                  </div>
                                </Swiper>
                                <div className="swiper-pagination swiper-pagination1"></div>
                              </div>
                            </div>
                          </FadeIn>
                        </FadeUpBox>
                      </Col>
                    </Row>
                  </Container>
                </section>
              )}
            </Scene>

            <Scene
              triggerElement="#discovery"
              reverse={false}
              duration={2000}
              offset={200}
            >
              {(progress, event) => (
                <section id="discovery">
                  <Container>
                    <Row>
                      <Col lg={5} sm={12} className="cms-logos">
                        <FadeUpBox pose={progress > 0 ? "enter" : "exit"}>
                          <FadeIn>
                            <img
                              src={
                                wesImages.sitefinityLogo.childImageSharp
                                  .fluid.src
                              }
                              alt="Sitefinity Logo"
                            />
                          </FadeIn>
                        </FadeUpBox>
                      </Col>

                      <Col lg={7} sm={12} className="desc">
                        <div>
                          <FadeUpBox pose={progress > 0 ? "enter" : "exit"}>
                            <FadeIn>
                              <h2>Sitefinity Custom Components </h2>
                              <p>
                                As part of the Sustainability work package Diviv built a custom Sitefinity
                                widget to allow users to work with complex tabulated data without having to
                                manually manipulate HTML or require the need for a developer to be involved.
                                Custom development of components such as widgets offer great ROI and can
                                drastically simplify complex user tasks and aid in the reuse of such components
                                site wide.
                              </p>
                            </FadeIn>
                          </FadeUpBox>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </section>
              )}
            </Scene>
          </Controller>

          <Controller>
            <Scene
              triggerElement="#solution"
              reverse={false}
              duration={2000}
              offset={200}
            >
              {(progress, event) => (
                <section
                  id="solution"
                  className="bg-cyan"
                  style={{ background: "none" }}
                >
                  <Container>
                    <Row>
                      <Col lg={4} sm={12} className="title">
                        <FadeUpBox pose={progress > 0 ? "enter" : "exit"}>
                          <FadeIn>
                            <h2>How we solve problems</h2>
                          </FadeIn>
                        </FadeUpBox>
                      </Col>

                      <Col lg={8} sm={12} className="desc">
                        <FadeUpBox pose={progress > 0 ? "enter" : "exit"}>
                          <FadeIn>
                            <p>
                              This particular project was not all smooth sailing,
                              during the delivery process it became clear that there
                              was a misunderstanding and miscommunication around some
                              of the deliverables. Thanks to having a great relationship
                              with the client, the Diviv and Sustainability team worked
                              together to get back on the same page and adjusted course
                              for a successful delivery. Sometimes a project team learns
                              more overcoming some adversity, by tackling issues in an
                              open and honest way we build long term trust with our customers.
                            </p>
                          </FadeIn>
                        </FadeUpBox>
                      </Col>
                    </Row>
                  </Container>
                </section>
              )}
            </Scene>
          </Controller>

          <Controller>
            <Scene
              triggerElement="#solution-video"
              reverse={false}
              duration={2000}
              offset={200}
            >
              {(progress, event) => (
                <section id="solution-video">
                  <Container className="py-5">
                    <Row>
                      <Col lg={12} sm={12}>
                        <FadeUpBox pose={progress > 0 ? "enter" : "exit"}>
                          <FadeIn>
                            <h2>SHOWCASE</h2>

                            <video
                              playsInline
                              controls
                              width="1078"
                              height="810"
                              poster={videoPosterWesfarmers}
                            >
                              <source src={wesfarmersVideo} type="video/mp4" />
                            </video>
                          </FadeIn>
                        </FadeUpBox>
                      </Col>
                    </Row>
                  </Container>
                </section>
              )}
            </Scene>
          </Controller>
        </div>

        {/*  <Scene
                    triggerElement="#bottom-section"
                    reverse={false}
                    duration={2000}
                    offset={200}
                >
                    {(progress, event) => (
                        <section className="quote-wrapper">
                            <Container>
                                <QuoteBlock
                                    author="Michael Johnson"
                                    authorPosition="Senior Business Analyst"
                                    quoteText="Diviv is a solution based partner that delivers
                              front and back end website solutions with
                              innovation, professionalism and integrity. They
                              are a great team and a pleasure to work with."
                                    clientLogoUrl={wesImages.logo.childImageSharp.fluid.src}
                                />
                            </Container>
                        </section>
                    )}
                </Scene> */}

        <CaseStudyNav
          nextUrl="/our-work/energy-action"
          nextImage={wesImages.eaCaseStudy.childImageSharp.fluid.src}
          nextTitle="Energy Action Portal"
        />
      </LayoutCaseStudy>
    </>
  );
}

export default WesfarmersSustainabilityPage;
